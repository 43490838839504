import * as PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { BUTTON } from "../helpers/constants"
import Cta from "./Cta"
import ageConfirmed from "../actions/ageVerification"

// eslint-disable-next-line no-shadow
const AgeVerification = ({ ageConfirmed, data: { text, ctaText, image } }) => {
  const cta = {
    type: BUTTON,
    text: ctaText,
    action: ageConfirmed,
    className: "consent-button"
  }

  return (
    <div className="age-verification">
      <img src={image} alt="" />
      <div className="age-verification__container">
        <div className="age-verification__inner">
          <div className="age-verification__text-wrapper">
            <p>{text}</p>
            <div className="age-verification__button-wrapper">
              <Cta {...cta} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ homePage }) => ({
  homePage
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ageConfirmed
    },
    dispatch
  )

AgeVerification.propTypes = {
  ageConfirmed: PropTypes.func.isRequired,
  data: PropTypes.objectOf(PropTypes.any).isRequired
}
export default connect(mapStateToProps, mapDispatchToProps)(AgeVerification)
