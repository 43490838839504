import * as PropTypes from "prop-types"
import React, { memo } from "react"
import { isMobileOnly } from "react-device-detect"

import Logo from "./Logo"
import MobileMenu from "./MobileMenu"
import Navigation from "./Navigation"

const Header = ({ isSticky, list, logo, isHome, showLangMenu, handleLanguageChange, landingData, pathname, availableLang, userLang }) => {
  const headerModifier = isSticky ? "page__header--sticky" : ""
  const navigationModifier = isSticky ? "theme--dark" : ""
  const logoModifier = isSticky ? "logo--short" : ""
  const logoMobileModifier = isMobileOnly ? "logo--short" : ""
  const headerHomeModifier = isHome ? "" : " page__header--subpage"

  return (
    <header className={`page__header flex-centered ${headerModifier} ${headerHomeModifier}`}>
      <div className="page__inner flex-space-between">
        <Logo modifier={`${logoModifier} ${logoMobileModifier}`} url={logo} />
        <div className="page__header--menu">
          {!isMobileOnly && (
            <Navigation
              list={list}
              modifier={navigationModifier}
              showLangMenu={showLangMenu}
              handleLanguageChange={handleLanguageChange}
              availableLang={availableLang}
              userLang={userLang}
            />
          )}
          {isMobileOnly && !landingData && (
            <MobileMenu
              list={list}
              isSticky={isSticky}
              isHome={pathname === "/"}
              showLangMenu={showLangMenu}
              handleLanguageChange={handleLanguageChange}
              availableLang={availableLang}
              userLang={userLang}
              logo={logo}
            />
          )}
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  isSticky: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  logo: PropTypes.string.isRequired,
  isHome: PropTypes.bool.isRequired,
  showLangMenu: PropTypes.bool.isRequired,
  handleLanguageChange: PropTypes.func,
  landingData: PropTypes.bool,
  pathname: PropTypes.string.isRequired,
  availableLang: PropTypes.arrayOf(PropTypes.any),
  userLang: PropTypes.string.isRequired
}

Header.defaultProps = {
  isSticky: false,
  handleLanguageChange: null,
  landingData: false,
  availableLang: null
}

export default memo(Header)
