/* eslint-disable react/button-has-type */
import * as PropTypes from "prop-types"
import React from "react"
import { NavLink } from "react-router-dom"

const Cta = ({ type, size, action, color, text, isRounded, url, mixModifier, brand }) => {
  const sizeModifier = size ? `cta--size--${size}` : ""
  const colorModifier = color ? `cta--color--${color}` : ""
  const roundedModifier = isRounded ? "cta--rounded" : ""
  const brandModifier = `cta--${brand}`

  const buttonSettings = {
    onClick: action
  }

  const externalLinkSettings = {
    href: url,
    onClick: action
  }

  const internalLinkSettings = {
    to: url,
    onClick: action
  }

  const className = `cta ${sizeModifier} ${colorModifier} ${roundedModifier} ${mixModifier} ${brandModifier}`

  const component = {
    button: (
      <button {...buttonSettings} className={className}>
        <span className="cta__text">{text}</span>
      </button>
    ),
    internalLink: (
      <NavLink {...internalLinkSettings} className={className}>
        <span className="cta__text">{text}</span>
      </NavLink>
    ),
    externalLink: (
      <a {...externalLinkSettings} className={className}>
        <span className="cta__text">{text}</span>
      </a>
    )
  }

  return component[type]
}

Cta.propTypes = {
  isRounded: PropTypes.bool,
  type: PropTypes.string.isRequired,
  url: PropTypes.string,
  size: PropTypes.string,
  action: PropTypes.func,
  color: PropTypes.string,
  text: PropTypes.string
}

Cta.defaultProps = {
  url: "",
  color: "",
  size: "",
  text: "",
  isRounded: false,
  action: () => {}
}

export default Cta
