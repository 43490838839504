/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import * as contentful from "contentful"

import { FETCH_DATA, FETCH_GAMES, FETCH_GAMES_CATEGORIES } from "./types"
import { getTranslations, getMenuIcon } from "../helpers"
import {
  PROMOTION,
  GAMES_CATEGORY,
  STATIC_PAGE,
  PROMOTIONS_PAGE,
  EVENTS_PAGE,
  HOME_PAGE,
  SIGN_UP,
  HEADER_NAVIGATION,
  FOOTER_NAVIGATION,
  PREVIEW
} from "../helpers/constants"

const {
  REACT_APP_CONTENTFUL_SPACE_ID: contentfulSpaceId,
  REACT_APP_CONTENTFUL_ACCESS_TOKEN: contentfulAccessToken,
  REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN: contentfulPreviewAccessToken,
  REACT_APP_HEADER_LINKS: headerLinks = "",
  REACT_APP_FOOTER_LINKS: footerLinks = "",
  REACT_APP_MODE: mode
} = process.env

const isPreview = mode === PREVIEW
const host = isPreview ? "preview.contentful.com" : "cdn.contentful.com"
const accessToken = isPreview ? contentfulPreviewAccessToken : contentfulAccessToken

const fetchData = lang => dispatch => {
  contentful
    .createClient({
      space: contentfulSpaceId,
      accessToken,
      host
    })
    .getEntries({ limit: 900 })
    .then(response => response.items.filter(({ fields: { language } }) => language === lang))
    .then(items => {
      const getFields = fieldName =>
        items.reduce(
          (
            sum,
            {
              fields,
              sys: {
                contentType: {
                  sys: { id }
                }
              }
            }
          ) => (fields && id === fieldName ? sum.concat(fields) : sum),
          []
        )

      const homePage = getFields(HOME_PAGE)[0]
      const promotions = getFields(PROMOTION)
      const promotionsPage = getFields(PROMOTIONS_PAGE)[0]
      const pastEventsPage = getFields(EVENTS_PAGE)[0]
      const allGamesCategories = getFields(GAMES_CATEGORY)
      const staticPage = getFields(STATIC_PAGE)
      const signUp = getFields(SIGN_UP)[0]

      promotionsPage &&
        (promotionsPage.promotionsList = promotionsPage
          ? promotionsPage.promotionsList.reduce((sum, { fields }) => (fields ? sum.concat(fields) : sum), [])
          : [])

      const pastEventsList = pastEventsPage ? pastEventsPage.promotionsList.reduce((sum, { fields }) => (fields ? sum.concat(fields) : sum), []) : []

      const {
        bannerSection = [],
        gamesCategories = [],
        contentSection = [],
        logo: {
          fields: {
            file: { url }
          }
        },
        licenceLogo: { fields: { file: { url: licenceLogo } = {} } = {} } = {},
        footerLogos = [],
        ageVerificationBackground: { fields: { file } = {} } = {},
        timeoutPopupImage: { fields: { file: { url: popupImageUrl } = {} } = {} } = {}
      } = homePage

      homePage.logo = url
      homePage.licenceLogo = licenceLogo
      homePage.ageVerificationBackground = file ? file.url : ""
      homePage.timeoutPopupImage = popupImageUrl

      homePage.bannerSection = bannerSection.reduce(
        (sum, { fields }) =>
          fields
            ? sum.concat({
                title: fields.bannerTitle,
                subTitle: fields.bannerSubtitle,
                ctaText: fields.ctaText,
                ctaLink: fields.ctaLink,
                mobileImage: fields.mobileImage.fields.file.url,
                desktopImage: fields.desktopImage.fields.file.url,
                legalLine: fields.legalLine
              })
            : sum,
        []
      )

      homePage.gamesCategories = gamesCategories.reduce(
        (sum, { fields }) =>
          fields
            ? sum.concat({
                categoryName: fields.categoryName,
                categoryDescription: fields.categoryDescription,
                slug: fields.slug,
                gamesList: [
                  ...new Set(
                    fields.gamesList.slice(0, fields.rows ? fields.rows * 5 : 10).reduce((sum, { fields }) => (fields ? sum.concat(fields) : sum), [])
                  )
                ]
              })
            : sum,
        []
      )

      homePage.contentSection = contentSection.reduce(
        (sum, { fields }) =>
          fields
            ? sum.concat({
                label: fields.label,
                text: fields.text,
                image: fields.image && fields.image.fields ? fields.image.fields.file.url : ""
              })
            : sum,
        []
      )

      homePage.footerLogos = footerLogos.reduce(
        (sum, { fields }) =>
          fields
            ? sum.concat({
                link: fields.title,
                img: fields.file && fields.file.url
              })
            : sum,
        []
      )

      allGamesCategories.sort((a, b) => a.priority - b.priority)

      allGamesCategories.forEach(item => {
        const { gamesList } = item
        item.gamesList = item.gamesList && [...new Set(gamesList.reduce((sum, { fields }) => (fields ? sum.concat(fields) : sum), []))]
      })

      const games = [...new Set(allGamesCategories.reduce((sum, { gamesList }) => (gamesList ? sum.concat(gamesList) : sum), []))]

      games.forEach(item => {
        if (item) {
          const {
            gameThumbnail: {
              fields: {
                file: { url }
              }
            }
          } = item
          item.gameThumbnail = url
        }
      })

      promotions.forEach(item => {
        const {
          mobilePromoImage: {
            fields: {
              file: { url: mobileUrl }
            }
          },
          desktopPromoImage: {
            fields: {
              file: { url: desktopUrl }
            }
          },
          mediaGallery
        } = item

        item.mobilePromoImage = mobileUrl
        item.desktopPromoImage = desktopUrl
        item.mediaGallery = mediaGallery && mediaGallery.map(item => item.fields.file.url)
      })

      staticPage.forEach(item => {
        const { extraContent = [] } = item
        const { bannerSection } = item
        const { gamesSection } = item
        item.extraContent = extraContent.reduce(
          (sum, { fields }) =>
            fields
              ? sum.concat({
                  label: fields.label,
                  text: fields.text,
                  image: fields.image && fields.image.fields ? fields.image.fields.file.url : ""
                })
              : sum,
          []
        )
        item.bannerSection =
          bannerSection &&
          bannerSection.reduce(
            (sum, { fields }) =>
              fields
                ? sum.concat({
                    title: fields.bannerTitle,
                    subTitle: fields.bannerSubtitle,
                    ctaText: fields.ctaText,
                    ctaLink: fields.ctaLink,
                    mobileImage: fields.mobileImage && fields.mobileImage.fields && fields.mobileImage.fields.file.url,
                    desktopImage: fields.desktopImage && fields.desktopImage.fields && fields.desktopImage.fields.file.url,
                    legalLine: fields.legalLine
                  })
                : sum,
            []
          )

        item.gamesSection =
          gamesSection &&
          gamesSection.reduce(
            (sum, { fields }) =>
              fields
                ? sum.concat({
                    categoryName: fields.categoryName,
                    slug: fields.slug,
                    gamesList: fields.gamesList.slice(0, fields.rows ? fields.rows * 5 : 10)
                  })
                : sum,
            []
          )
      })

      const isEU = lang === "ca-eu" || lang === "fr-eu"

      const translationsLang = lang.includes("-") || !isEU ? lang.substring(lang.indexOf("-") + 1) : lang

      const _translationsLang = isEU ? lang.slice(0, 2) : translationsLang

      const translations = getTranslations(_translationsLang)

      const getNavigation = navigation =>
        staticPage.reduce(
          (sum, { pageTitle, includeIn, slug }) => (includeIn && includeIn.includes(navigation) ? sum.concat({ pageTitle, slug }) : sum),
          []
        )

      const getExtraNavigation = links =>
        links.split(",").map(item => ({
          pageTitle: translations.links[item],
          slug: translations.slugs[item],
          icon: getMenuIcon(item)
        }))

      const headerNavigation = getNavigation(HEADER_NAVIGATION).concat(getExtraNavigation(headerLinks))

      const footerNavigation = getNavigation(FOOTER_NAVIGATION).concat(getExtraNavigation(footerLinks))

      dispatch({
        type: FETCH_DATA,
        homePage,
        games,
        allGamesCategories,
        promotions,
        promotionsPage,
        pastEventsPage: pastEventsList,
        staticPages: staticPage,
        signUp,
        headerNavigation,
        footerNavigation,
        translations
      })
      dispatch({
        type: FETCH_GAMES,
        games
      })
      dispatch({
        type: FETCH_GAMES_CATEGORIES,
        categories: allGamesCategories
      })
    })
}

export default fetchData
