/* eslint-disable no-shadow */
import * as PropTypes from "prop-types"
import React from "react"
import ReactMarkdown from "react-markdown"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import Cta from "./Cta"
import { dismissModal } from "../actions/modalActions"
import { VISIBLE, BUTTON } from "../helpers/constants"

const Modal = ({
  data: { ctaType, content, title, image, ctaText, ctaUrl, action, ctaSize, modalModifier },
  isModalVisible,
  dismissModal,
  restartTimeout,
  translations
}) => {
  const _visible = isModalVisible ? VISIBLE : ""

  const timeoutRestartAction = () => {
    restartTimeout()
    dismissModal()
  }

  const modalAction = action ? timeoutRestartAction : dismissModal

  const cta = {
    text: ctaText || translations.application.labels.close,
    mixModifier: "cta--rounded",
    type: ctaType || BUTTON,
    size: ctaSize,
    action: modalAction,
    url: ctaUrl || null,
    className: "modal__close-button"
  }

  return (
    <div className={`modal modal--${_visible}`}>
      <div className={`modal__inner modal__inner--${modalModifier}`}>
        <div className="modal__column">
          {title && (
            <div className="modal__header">
              <h3 className="modal__title">{title}</h3>
            </div>
          )}

          {content && (
            <div className={`modal__body modal__body--${modalModifier}`}>
              <ReactMarkdown source={content} escapeHtml={false} />
            </div>
          )}

          <div className="modal__footer">
            <Cta {...cta} />
          </div>
        </div>

        <div className="modal__column">{image && <img src={image} alt="popup" />}</div>
      </div>

      <div className={`modal__overlay modal__overlay--${_visible}`} onClick={modalAction} role="presentation" />
    </div>
  )
}

Modal.defaultProps = {
  children: null,
  data: {},
  restartTimeout: null
}

Modal.propTypes = {
  children: PropTypes.node,
  data: PropTypes.objectOf(PropTypes.any),
  isModalVisible: PropTypes.bool.isRequired,
  dismissModal: PropTypes.func.isRequired,
  restartTimeout: PropTypes.func,
  translations: PropTypes.objectOf(PropTypes.any).isRequired
}

const mapStateToProps = ({ modal, translations }) => ({
  isModalVisible: modal.isModalVisible,
  data: modal.data,
  translations
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dismissModal
    },
    dispatch
  )

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
