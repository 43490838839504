import { DISMISS_MODAL, OPEN_MODAL } from "./types"

const dismissModal = () => dispatch =>
  dispatch({
    type: DISMISS_MODAL
  })

const openModal = data => dispatch =>
  dispatch({
    type: OPEN_MODAL,
    payload: data
  })

export { dismissModal, openModal }
