export const TOGGLE_STICKY = "header/TOGGLE_STICKY"
export const FETCH_GTM_AVAILABILITY = "head/FETCH_GTM_AVAILABILITY"
export const FETCH_DATA = "data/FETCH_DATA"
export const CHANGE_SLIDE = "heroSlider/CHANGE_SLIDE"
export const DISMISS_MODAL = "modal/DISMISS_MODAL"
export const OPEN_MODAL = "modal/OPEN_MODAL"
export const SHOW_TOAST = "toast/SHOW_TOAST"
export const HIDE_TOAST = "toast/HIDE_TOAST"
export const FETCH_EVENTS = "odds/FETCH_EVENTS"
export const FETCH_EVENTS_ERROR = "odds/FETCH_EVENTS_ERROR"
export const FILTER_GAMES = "games/FILTER_GAMES"
export const FETCH_GAMES = "data/FETCH_GAMES"
export const FETCH_GAMES_CATEGORIES = "data/FETCH_GAMES_CATEGORIES"
export const VIEW_MORE = "gallery/VIEW_MORE"
export const TOGGLE_LIGHTBOX = "gallery/TOGGLE_LIGHTBOX"
export const UPDATE_INDEX = "gallery/UPDATE_INDEX"
export const CONFIRM_AGE = "ageVerification/CONFIRM_AGE"
