import * as PropTypes from "prop-types"
import React, { memo } from "react"
import ReactMarkdown from "react-markdown"

import Navigation from "./Navigation"
import { isUrl } from "../helpers"

const Footer = ({ footerInfo }) => {
  const { footerNavigationItems, copyright, licenceLogo, footerDisclaimer, footerLogos } = footerInfo
  return (
    <footer className="page__footer">
      <div className="page__inner flex-space-between">
        <Navigation list={footerNavigationItems} modifier="small" />
        <p className="copyright">{copyright}</p>
      </div>
      <div className="page__inner">
        {licenceLogo && (
          <a
            href="https://authorisation.mga.org.mt/verification.aspx?lang=EN&company=726b906b-4fe8-4cf9-9294-0a6a8a808d90&details=1"
            target="_blank"
            rel="noreferrer"
          >
            <img src={licenceLogo} alt="licence logo" width="100%" height="100%" />
          </a>
        )}
        <div className="disclaimer">
          <ReactMarkdown source={footerDisclaimer} />
        </div>
        <div className="logos">
          {footerLogos &&
            footerLogos.map((i, index) => {
              const key = index

              if (isUrl(i.link)) {
                return (
                  <div key={key}>
                    <a href={i.link}>
                      <img alt="" key={key} src={i.img} width="100%" height="100%" />
                    </a>
                  </div>
                )
              }

              return (
                <div key={key}>
                  <img alt="" src={i.img} width="100%" height="100%" />
                </div>
              )
            })}
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  footerInfo: PropTypes.objectOf(PropTypes.any).isRequired
}

export default memo(Footer)
