import { TOGGLE_STICKY } from "./types"

const toggleSticky = isSticky => {
  return dispatch => {
    dispatch({
      type: TOGGLE_STICKY,
      isSticky
    })
  }
}

export default toggleSticky
