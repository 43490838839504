import { CHANGE_SLIDE } from "../actions/types"

const initialState = {
  slide: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SLIDE:
      return {
        ...state,
        slide: action.slide
      }

    default:
      return state
  }
}
