export const GAMES = "games"
export const INTERNAL_LINK = "internalLink"
export const EXTERNAL_LINK = "externalLink"
export const BUTTON = "button"
export const SUBMIT = "submit"
export const LARGE = "large"
export const MEDIUM = "medium"
export const WIDE = "wide"
export const PURE = "pure"
export const PROMO = "promo"
export const MAIN = "main"
export const PAGE = "page"
export const SCROLL = "scroll"
export const PROMOTION = "promotion"
export const PROMOTIONS_PAGE = "promotionsPage"
export const EVENTS_PAGE = "pastEventsPage"
export const STATIC_PAGE = "staticPage"
export const PROMO_PAGE = "promoPage"
export const HOME_PAGE = "homepage"
export const GAME_PAGE = "gamespage"
export const SIGN_UP = "signUp"
export const GAMES_CATEGORY = "gamesCategory"
export const HEADER_NAVIGATION = "Header Navigation"
export const FOOTER_NAVIGATION = "Footer Navigation"
export const DE = "de"
export const CA = "ca"
export const EN = "en"
export const IT = "it"
export const BR = "br"
export const NO = "no"
export const FI = "fi"
export const IN = "in"
export const ES = "es"
export const NZ = "nz"
export const CL = "cl"
export const PE = "pe"
export const JP = "jp"
export const FR = "fr"
export const AFR = "afr"
export const SE = "se"
export const SV = "sv"
export const HN = "hn"
export const POST = "POST"
export const GET = "GET"
export const TEXT = "text"
export const EMAIL = "email"
export const CHECKBOX = "checkbox"
export const VISIBLE = "visible"
export const ERROR = "error"
export const SUCCESS = "success"
export const ON = "on"
export const OFF = "off"
export const NETWORK_ERROR = "Network Error"
export const TERMS_AND_CONDITIONS = "termsAndConditions"
export const CAMPAIGN_ID = "campaignId"
export const AUTO = "auto"
export const CARD = "card"
export const SLIDE = "slide"
export const HERO = "hero"
export const DEVELOPMENT = "development"
export const FUNCTION = "function"
export const DATE = "date"
export const PREVIEW = "preview"
export const JS = "js"
export const CONFIG = "config"
export const EVENT = "event"
export const CONVERSION = "conversion"
export const SEARCH = "search"
export const MOBILE = "mobile"
export const WEB = "web"
export const AUTO_LAUNCH_GAME_SLUG = "autoLaunchGameSlug"
export const SIGNUP = "Signup"
