/* eslint-disable no-shadow */
import PropTypes from "prop-types"
import React, { Suspense, lazy, useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { connect } from "react-redux"
import { Route, Switch, matchPath, useLocation } from "react-router-dom"

import AgeVerification from "./AgeVerification"
import CookieConsent from "./CookieConsent"
import Footer from "./Footer"
import Header from "./Header"
import Loading from "./Loading"
import NotAvailable from "./NotAvailable"
import fetchData from "../actions/dataActions"
import fetchGtmAvailability from "../actions/headActions"
import toggleSticky from "../actions/headerActions"
import { getPage, useCookieConsent } from "../helpers"
import { initialize } from "../helpers/gtmManager"

const Home = lazy(() => import("../pages/Home"))
const Games = lazy(() => import("../pages/Games"))
const Info = lazy(() => import("../pages/Info"))
const SignUp = lazy(() => import("../pages/SignUp"))
const Promotions = lazy(() => import("../pages/Promotions"))
const PastEvents = lazy(() => import("../pages/PastEvents"))
const LandingPage = lazy(() => import("../pages/LandingPage"))

const { REACT_APP_DOMAIN_ZONE: _domainZone, REACT_APP_LANG: siteLang, REACT_APP_BRAND: brand } = process.env

const domainZone = _domainZone.includes("-") ? _domainZone.substring(0, _domainZone.lastIndexOf("-")) : _domainZone

const contentDomainZone = _domainZone === "ca-eu" || _domainZone === "fr-eu" || _domainZone === "nz-eu" ? _domainZone : domainZone

const App = props => {
  const availableLang = siteLang && siteLang?.split(",").map(language => language)
  const storedLang = availableLang?.length > 1 && sessionStorage.getItem("language")

  const defaultLang = siteLang ? availableLang[0] : contentDomainZone

  const [subdivision, setSubdivision] = useState()
  const [lang, setLang] = useState(storedLang || defaultLang)

  const handleLanguageChange = lang => {
    sessionStorage.setItem("language", lang)
    setLang(lang)
    window.location.reload()
  }

  const contentfulLang = siteLang ? `${contentDomainZone}-${lang}` : contentDomainZone

  const { pathname } = useLocation()

  const {
    fetchData,
    isSticky,
    copyright,
    logo,
    homePage: {
      showAgeVerificationWall,
      ageVerificationText,
      ageVerificationCta,
      ageVerificationBackground,
      licenceLogo,
      footerDisclaimer,
      footerLogos
    },
    headerNavigation: { headerNavigationItems },
    footerNavigation: { footerNavigationItems },
    ageConfirmed,
    promotions,
    promotionsPage,
    translations,
    staticPages
  } = props

  const isSignUpHidden = !!+process.env.REACT_APP_HIDE_SIGNUP
  const isLangMenuAvailable = !!+process.env.REACT_APP_SHOW_LANG_MENU

  const { showConsent, shouldLoadGtm, accept } = useCookieConsent()

  useEffect(() => {
    if (shouldLoadGtm) {
      initialize()
    }
  }, [shouldLoadGtm])

  useEffect(() => {
    fetchData(contentfulLang)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    const fetchGeo = async () => {
      const url = `https://leo-promo-redirect-service.leo-prod-common.lvg-tech.net/_geofetch`

      const res = await fetch(url)
      const r = await res.json()
      setSubdivision(r?.subdivision.toLowerCase())
    }
    fetchGeo()
  }, [setSubdivision])

  const ageVerificationContent = {
    text: ageVerificationText,
    ctaText: ageVerificationCta,
    image: ageVerificationBackground
  }

  const footerInfo = {
    footerNavigationItems,
    copyright,
    licenceLogo,
    footerDisclaimer,
    footerLogos
  }

  if (!ageConfirmed && showAgeVerificationWall) {
    return <AgeVerification data={ageVerificationContent} />
  }

  const loading = <Loading />
  const showLangMenu = isLangMenuAvailable

  if (subdivision === "caon") {
    return <NotAvailable logo={logo} />
  }

  if (Object.keys(translations).length > 1) {
    const { slugs } = translations
    const isSingleGamePage = pathname.includes(`/${slugs.games}`) && pathname.split("/")[2]

    const isFooterVisible = !isSingleGamePage || !isMobile

    const landingPagePath = matchPath(window.location.pathname, `/${promotions}/:id`)
    let landingData

    if (landingPagePath && landingPagePath.isExact && landingPagePath.params.id) {
      landingData = getPage(promotions, landingPagePath.params.id)

      if (landingData && !landingData.isLandingPage) {
        landingData = null
      }
    }

    if (!matchPath(window.location.pathname, `/${slugs.games}/:id`)) {
      localStorage.removeItem("autoLaunchGameSlug")
    }
    return (
      <div className={`page theme-${brand}`}>
        <Header
          isSticky={isSticky}
          list={!landingData ? headerNavigationItems : []}
          logo={logo}
          isHome={pathname === "/"}
          showLangMenu={showLangMenu}
          handleLanguageChange={siteLang && handleLanguageChange}
          availableLang={availableLang}
          landingData={landingData}
          pathname={pathname}
          userLang={lang}
        />
        {landingData ? (
          <Suspense fallback={loading}>
            <LandingPage {...getPage(promotions, landingPagePath.params.id)} />
          </Suspense>
        ) : (
          <main className="page__body">
            <Suspense fallback={loading}>
              <Switch>
                <Route exact path="/">
                  <Home />
                </Route>
                <Route exact path={`/${slugs.games}`}>
                  <Games />
                </Route>
                <Route exact path={`/${slugs.games}/:id`}>
                  <Games />
                </Route>
                <Route exact path={`/${slugs.games}/${slugs.category}/:category`}>
                  <Games />
                </Route>

                <Route exact path={`/${promotionsPage.slug}`}>
                  <Promotions />
                </Route>
                <Route exact path={`/${promotionsPage.slug}/:id`}>
                  <Promotions />
                </Route>

                {!isSignUpHidden && (
                  <Route exact path={`/${slugs.signUp}`}>
                    <SignUp />
                  </Route>
                )}

                <Route exact path={`/${translations.slugs.pastEvents}`}>
                  <PastEvents />
                </Route>
                <Route exact path={`/${translations.slugs.pastEvents}/:id`}>
                  <PastEvents />
                </Route>

                <Route exact path="/:id">
                  <Info />
                </Route>
              </Switch>
            </Suspense>
          </main>
        )}

        {showConsent && (
          <CookieConsent onAccept={accept} cookiePolicy={staticPages.filter(({ slug }) => slug === translations.slugs.cookiePolicy)[0].pageContent} />
        )}

        {isFooterVisible && <Footer footerInfo={footerInfo} />}
        {
          // test
        }
      </div>
    )
  }

  return null
}

const mapStateToProps = ({
  header,
  router,
  head,
  homePage,
  games,
  promotions,
  promotionsPage,
  headerNavigation,
  footerNavigation,
  ageVerification,
  translations,
  staticPages
}) => ({
  isSticky: header.isSticky,
  copyright: homePage.copyright,
  router,
  head,
  homePage,
  games,
  promotions,
  promotionsPage,
  headerNavigation,
  footerNavigation,
  logo: homePage.logo,
  ageConfirmed: ageVerification.ageConfirmed,
  translations,
  staticPages
})

const mapDispatchToProps = {
  fetchGtmAvailability,
  fetchData,
  toggleSticky
}

App.propTypes = {
  isSticky: PropTypes.bool.isRequired,
  fetchGtmAvailability: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  headerNavigation: PropTypes.objectOf(PropTypes.any).isRequired,
  footerNavigation: PropTypes.objectOf(PropTypes.any).isRequired,
  copyright: PropTypes.string,
  logo: PropTypes.string,
  homePage: PropTypes.objectOf(PropTypes.any).isRequired,
  acceptanceDate: PropTypes.string,
  router: PropTypes.objectOf(PropTypes.any).isRequired,
  ageConfirmed: PropTypes.bool.isRequired,
  promotions: PropTypes.arrayOf(PropTypes.any),
  promotionsPage: PropTypes.objectOf(PropTypes.any),
  translations: PropTypes.objectOf(PropTypes.any).isRequired,
  staticPages: PropTypes.arrayOf(PropTypes.any).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  })
}

App.defaultProps = {
  copyright: "",
  logo: "",
  acceptanceDate: null,
  promotions: [],
  promotionsPage: {},
  match: {
    params: {
      id: null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
