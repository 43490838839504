import { FETCH_DATA } from "../actions/types"

const initialState = []

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DATA: {
      return [...state, ...action.pastEventsPage]
    }
    default:
      return state
  }
}
