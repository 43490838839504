import { HIDE_TOAST, SHOW_TOAST } from "../actions/types"

const initialState = {
  visible: false,
  type: "",
  data: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TOAST: {
      return {
        ...state,
        visible: true,
        data: action.payload
      }
    }

    case HIDE_TOAST: {
      return {
        ...state,
        visible: false
      }
    }

    default:
      return state
  }
}
