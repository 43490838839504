import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"
import { reducer as formReducer } from "redux-form"

import ageVerificationReducer from "./ageVerificationReducer"
import footerNavigationReducer from "./footerNavigationReducer"
import gamesReducer from "./gamesReducer"
import headerNavigationReducer from "./headerNavigationReducer"
import headerReducer from "./headerReducer"
import headReducer from "./headReducer"
import heroSliderReducer from "./heroSliderReducer"
import homePageReducer from "./homePageReducer"
import mediaGalleryReducer from "./mediaGalleryReducer"
import modalReducer from "./modalReducer"
import oddsReducer from "./oddsReducer"
import pastEventsReducer from "./pastEventsReducer"
import promotionsPageReducer from "./promotionsPageReducer"
import promotionsReducer from "./promotionsReducer"
import signUpReducer from "./signUpReducer"
import staticPagesReducer from "./staticPagesReducer"
import toastReducer from "./toastReducer"
import translationsReducer from "./translationsReducer"

export default history =>
  combineReducers({
    router: connectRouter(history),
    header: headerReducer,
    modal: modalReducer,
    games: gamesReducer,
    head: headReducer,
    homePage: homePageReducer,
    pastEventsPage: pastEventsReducer,
    promotions: promotionsReducer,
    promotionsPage: promotionsPageReducer,
    staticPages: staticPagesReducer,
    signUp: signUpReducer,
    headerNavigation: headerNavigationReducer,
    footerNavigation: footerNavigationReducer,
    heroSlider: heroSliderReducer,
    toast: toastReducer,
    form: formReducer,
    odds: oddsReducer,
    gallery: mediaGalleryReducer,
    ageVerification: ageVerificationReducer,
    translations: translationsReducer
  })
