import * as PropTypes from "prop-types"
import React from "react"
import { isMobileOnly } from "react-device-detect"
import { NavLink } from "react-router-dom"

import LanguageMenu from "./LanguageMenu"

const Navigation = ({ list, modifier, onClick, showLangMenu, handleLanguageChange, availableLang, userLang }) => (
  <nav className={`navigation navigation--${modifier}`}>
    <ul className="navigation__items">
      {list.map(({ slug, pageTitle, icon }, index) => {
        const _index = `ni-${index}`

        return (
          <li key={_index} className="navigation__item">
            <NavLink to={`/${slug}`} exact className="navigation__link" activeClassName="navigation__link--active" onClick={onClick}>
              {isMobileOnly && <div className="icon">{icon}</div>}
              {pageTitle}
            </NavLink>
          </li>
        )
      })}
    </ul>
    {showLangMenu && <LanguageMenu handleLanguageChange={handleLanguageChange} availableLang={availableLang} userLang={userLang} />}
  </nav>
)

Navigation.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  modifier: PropTypes.string,
  onClick: PropTypes.func,
  showLangMenu: PropTypes.bool,
  handleLanguageChange: PropTypes.func,
  availableLang: PropTypes.arrayOf(PropTypes.any),
  userLang: PropTypes.string
}

Navigation.defaultProps = {
  modifier: "",
  onClick: () => {},
  showLangMenu: false,
  handleLanguageChange: null,
  availableLang: null,
  userLang: null
}

export default Navigation
