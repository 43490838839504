/* eslint-disable func-names */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react"
import TagManager from "react-gtm-module"

import { JS, CONFIG, EVENT, CONVERSION, EN, CA, DE, IT, BR, FI, IN, ES, NZ, PE, CL, JP, FR, AFR, HN, SE, SV } from "./constants"
import translations from "./translations"
import { ReactComponent as SignUpIcon } from "../assets/icons/signup.svg"
import { ReactComponent as SlotIcon } from "../assets/icons/slot.svg"

const { REACT_APP_GTM_ID: gtmId } = process.env

const getPage = (pages, pageId) => {
  if (!pageId) {
    return undefined
  }
  return pages.find(page => page.slug === pageId)
}

export const initialize = () => {
  TagManager.initialize({
    gtmId,
    dataLayer: []
  })
}

const pushDatalayer = (event, category, action, label) => {
  if (!window.dataLayer) {
    return
  }

  window.dataLayer.push({
    event,
    category,
    action,
    label
  })
}

const getDecimalOdds = americanOdds => (americanOdds > 0 ? americanOdds / 100 + 1 : -(100 / americanOdds) + 1).toFixed(2)

const addGtm = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    originalLocation: `${document.location.protocol}//${document.location.hostname}${document.location.pathname}${document.location.search}`
  })
  ;(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js"
    })
    const f = d.getElementsByTagName(s)[0]
    const j = d.createElement(s)
    const dl = l !== "dataLayer" ? `&l=${l}` : ""
    j.async = true
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`
    f.parentNode.insertBefore(j, f)
  })(window, document, "script", "dataLayer", gtmId)
}

const addGtag = (gtagId, gtagHash) => {
  window.dataLayer = window.dataLayer || []

  function gtag() {
    window.dataLayer.push(arguments)
  }

  gtag(JS, new Date())

  gtag(CONFIG, gtagId)

  gtag(EVENT, CONVERSION, { send_to: `${gtagId}/${gtagHash}` })
}

const loadingEvent = (condition, callback) => condition && callback && callback()

const loadingHandler = (...args) => args.forEach(arg => arg)

const handleSuccess = () => {}

const handleError = error => {
  // eslint-disable-next-line no-console
  console.log("--- Error: ", error.message)
}
const isUrl = s => {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/
  return regexp.test(s)
}

const getFont = brand => {
  switch (brand) {
    case "leovegas":
      return "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
    case "royalpanda":
      return "https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap"
    case "gogocasino":
      return "../assets/gogocasino/fonts/grold-light-webfont.woff"
    case "livecasino":
      return "../assets/livecasino/BrownLLWeb-Light.otf"
    case "pinkcasino":
      return "../assets/pinkcasino/fonts/BarlowCondensed-Regular.woff"
    default:
      return "https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&family=Roboto:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap"
  }
}

const getBannerFont = brand => {
  switch (brand) {
    case "gogocasino":
      return "../assets/gogocasino/fonts/Black-Slabbath.woff"
    case "livecasino":
      return "../../assets/livecasino/fonts/WulkanDisplay-Bold.woff"
    default:
      return null
  }
}

const getLang = country => {
  switch (country) {
    case CA:
      return EN
    case FR:
      return FR
    case NZ:
    case AFR:
      return EN
    case DE:
      return DE
    case IT:
      return IT
    case BR:
      return BR
    case FI:
      return FI
    case SE:
      return SE
    case JP:
      return JP
    case IN:
      return IN
    case CL:
    case PE:
    case HN:
    case SV:
      return ES
    default:
      return EN
  }
}

const getTranslations = lang => {
  const language = getLang(lang)
  return translations[language]
}

const getOperatorUid = (brand, isBrandedGame) => {
  if (isBrandedGame) {
    switch (brand) {
      case "royalpanda":
        return "RoyalPanda"
      default:
        return "Gutro"
    }
  }
  return "Gutro"
}

const getMenuIcon = item => {
  switch (item) {
    case "games":
      return <SlotIcon />
    case "signUp":
      return <SignUpIcon />
    default:
      return <SlotIcon />
  }
}

export function pushConsentToDataLayer() {
  // eslint-disable-next-line prefer-rest-params
  dataLayer.push(arguments)
}

const useCookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false)
  const [shouldLoadGtm, setShouldLoadGtm] = useState(false)

  const createExpiryDate = () => {
    const expiryDate = new Date()
    expiryDate.setDate(expiryDate.getDate() + 183)
    return expiryDate
  }

  const accept = consent => {
    const gtagConsentMode = {
      security_storage: consent.necessary ? "granted" : "denied",
      functionality_storage: consent.functional ? "granted" : "denied",
      ad_storage: consent.marketing ? "granted" : "denied",
      analytics_storage: consent.analytics ? "granted" : "denied",
      personalization_storage: consent.necessary ? "granted" : "denied",
      ad_user_data: consent.marketing ? "granted" : "denied",
      ad_personalization: consent.marketing ? "granted" : "denied"
    }

    const lsConsentMode = {
      ...gtagConsentMode,
      expiry: createExpiryDate()
    }

    pushConsentToDataLayer("consent", "update", gtagConsentMode)

    localStorage.setItem("consentMode", JSON.stringify(lsConsentMode))

    setShowConsent(false)
    setShouldLoadGtm(true)
  }

  useEffect(() => {
    const consentMode = JSON.parse(localStorage.getItem("consentMode"))

    if (consentMode) {
      const expiryDate = Date.parse(consentMode.expiry)

      if (Date.now() < expiryDate) {
        setShouldLoadGtm(true)
        return
      }
    }
    setShowConsent(true)
  }, [])

  return {
    showConsent,
    shouldLoadGtm,
    accept
  }
}

export {
  getPage,
  pushDatalayer,
  getDecimalOdds,
  addGtag,
  addGtm,
  loadingHandler,
  loadingEvent,
  handleSuccess,
  handleError,
  isUrl,
  getFont,
  getBannerFont,
  getLang,
  getOperatorUid,
  getTranslations,
  getMenuIcon,
  useCookieConsent
}
