import { TOGGLE_STICKY } from "../actions/types"

const initialState = {
  isSticky: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_STICKY:
      return {
        ...state,
        isSticky: action.isSticky
      }

    default:
      return state
  }
}
