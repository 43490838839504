import { FETCH_GAMES, FETCH_GAMES_CATEGORIES, FILTER_GAMES } from "../actions/types"

const initialState = {
  items: [],
  categories: [],
  filter: ""
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GAMES: {
      return {
        ...state,
        items: action.games
      }
    }
    case FETCH_GAMES_CATEGORIES: {
      return {
        ...state,
        categories: action.categories
      }
    }
    case FILTER_GAMES: {
      return {
        ...state,
        filter: action.filter
      }
    }
    default:
      return state
  }
}
