/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { isMobileOnly } from "react-device-detect"

import "../styles/blocks/language-menu.scss"
import globeIcon from "../assets/icons/globe-icon.svg"

const { REACT_APP_DOMAIN_ZONE: domainZone } = process.env

const languagesArray = [
  { language: "English", langCode: "en" },
  { language: "Finnish", langCode: "fi" },
  { language: "Swedish", langCode: "se" }
]

const LanguageMenu = ({ handleLanguageChange, availableLang, userLang, isMobileMenuVisible }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isMobileMenuVisible) setIsVisible(false)
  }, [isMobileMenuVisible])

  const domain = window.location.hostname.split(".").slice(-2).join(".")
  const langArray = [
    {
      lang: "English",
      href: `${domain}`
    },
    {
      lang: "Français",
      href: `fr.${domain}`
    }
  ]

  const handleMenuButtonClick = () => {
    if (isVisible) {
      setIsVisible(false)
    } else {
      setIsVisible(true)
    }
  }

  const languages = availableLang && languagesArray.reduce((sum, item) => (availableLang.includes(item.langCode) ? sum.concat(item) : sum), [])

  const currenLang = userLang.slice(0, 2) === "ca" ? "en" : userLang.slice(0, 2)

  const isCanada = domainZone.slice(0, 2) === "ca" || domainZone.slice(0, 2) === "fr"

  const menuModifier = isVisible ? "dropdown--menu-visible" : ""

  return (
    <div className={`dropdown ${menuModifier}`}>
      {!isMobileOnly && (isCanada || availableLang) && (
        <div role="button" aria-hidden className="dropbtn" onClick={handleMenuButtonClick}>
          <span>{currenLang}</span>
          <img src={globeIcon} alt="languages" />
        </div>
      )}
      {isMobileOnly && (
        <div className="language">
          <div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>
          </div>
          <p className="language__title">Languages:</p>
        </div>
      )}
      <div className="dropdown-content">
        {availableLang &&
          languages.map(item => {
            const activeModifier = item.langCode === userLang ? "lang--active" : ""
            return (
              <a
                href="/"
                className={`lang ${activeModifier}`}
                key={item.language}
                // eslint-disable-next-line react/no-unknown-property
                imgurl={item.image}
                onClick={() => handleLanguageChange(item.langCode)}
              >
                <span>{item.language}</span>
                <span> - {item.langCode}</span>
              </a>
            )
          })}
        {isCanada &&
          langArray.map((item, i) => {
            const activeModifier = item.href === window.location.host.replace("www.", "") ? "lang--active" : ""
            return (
              <a href={`https://${item.href}`} className={`lang ${activeModifier}`} key={i}>
                {item.lang}
              </a>
            )
          })}
      </div>
    </div>
  )
}

LanguageMenu.propTypes = {
  handleLanguageChange: PropTypes.func,
  availableLang: PropTypes.arrayOf(PropTypes.any),
  userLang: PropTypes.string.isRequired,
  isMobileMenuVisible: PropTypes.bool
}

LanguageMenu.defaultProps = {
  handleLanguageChange: null,
  availableLang: null,
  isMobileMenuVisible: false
}

export default LanguageMenu
