import * as PropTypes from "prop-types"
import React from "react"
import "../styles/blocks/not-available.scss"

const NotAvailable = ({ logo }) => {
  return (
    <div className="container">
      <div className="box">
        <div className="box__logo">
          <img alt="logo" src={logo} width="200" height="50" />
        </div>
        <p>Unfortunately, this website is not available in Ontario.</p>
      </div>
    </div>
  )
}

NotAvailable.propTypes = {
  logo: PropTypes.string.isRequired
}

NotAvailable.defaultProps = {}

export default NotAvailable
