import PropTypes from "prop-types"
import React, { useState } from "react"

const Toggle = ({ title, body, isOn = false, disabled, onChange }) => {
  const [on, setOn] = useState(isOn)

  const handleChange = () => {
    if (disabled) return

    const newState = !on
    setOn(newState)
    onChange(newState)
  }

  return (
    <button className={`toggle ${disabled && "toggle--disabled"}`} onClick={handleChange} type="button">
      <div className={`toggle__icon ${on && "toggle__icon--on"}`}>
        <div className={`toggle__pin ${on && "toggle__pin--on"}`} />
      </div>
      <div className="toggle__text">
        <p className="toggle__title">{title}</p>
        <div className="toggle__body">{body}</div>
      </div>
    </button>
  )
}

Toggle.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  isOn: PropTypes.bool,
  onChange: PropTypes.func.isRequired
}

Toggle.defaultProps = {
  isOn: false,
  disabled: false
}

export default Toggle
