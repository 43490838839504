import { routerMiddleware } from "connected-react-router"
import { createBrowserHistory } from "history"
import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"

import { DEVELOPMENT } from "./helpers/constants"
import rootReducer from "./reducers"

export const history = createBrowserHistory()

const initialState = {}
const enhancers = []

if (process.env.NODE_ENV === DEVELOPMENT) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === "function") {
    enhancers.push(devToolsExtension())
  }
}

const store = createStore(rootReducer(history), initialState, compose(applyMiddleware(routerMiddleware(history), thunk)))

export default store
