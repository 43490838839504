import { FETCH_GTM_AVAILABILITY } from "../actions/types"

const initialState = {
  isGtmAvailable: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_GTM_AVAILABILITY: {
      return {
        ...state,
        isGtmAvailable: action.isGtmAvailable
      }
    }
    default:
      return state
  }
}
