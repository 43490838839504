const translations = {
  en: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "games",
      promotions: "promotions",
      partnerships: "partnerships",
      category: "category",
      signUp: "sign-up",
      success: "success",
      termsConditions: "terms-and-conditions",
      pastEvents: "past-events",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Games",
      promotions: "Promotions",
      signUp: "Sign Up",
      pastEvents: "Past Events",
      allGames: "All Games",
      viewMore: "View More",
      showAll: "Show All",
      language: "Language"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "Error 404",
        pageNotFound: "Page not found",
        required: "Required",
        somethingWentWrong: "Something went wrong",
        invalidEmail: "Invalid email address",
        mustBeNumber: "Must be a number",
        mustBeOlder: "Sorry, you must be at least 18 years old",
        mustAcceptTC: "You must accept terms and conditions",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Invalid birthday date"
      },
      messages: {
        successfulSignUp: "Success! Check email for more details. If email does not arrive immediately, please check your junk mail folder!",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "Sign Up",
        fillForm: "Please fill in the form",
        fullName: "full name",
        email: "email",
        mobileNumber: "mobile number",
        age: "age",
        termsConditions: "terms and conditions",
        privacyPolicy: "Privacy Policy",
        ageConfirmation: "I confirm I am over 18 years old",
        subscriptionConfirmation:
          "I want to be contacted and informed about LeoVegas Gaming Plc’s products and services (new features, campaigns and offers) via Email/SMS",
        accept: "I confirm that I have read and understood the",
        terms: "terms",
        promotions: "promotions",
        submit: "submit",
        fiscalCode: "Fiscal code",
        birthdayDate: "Date of birth",
        close: "Close",
        search: "Search",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  de: {
    slugs: {
      games: "spiele",
      promotions: "angebote",
      partnerships: "[DE] partnerships",
      category: "category",
      signUp: "anmelden",
      success: "erfolg",
      termsConditions: "nutzungsbedingungen",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Spiele",
      promotions: "Angebote",
      signUp: "Anmelden",
      pastEvents: "Past Events",
      allGames: "Spiele",
      viewMore: "View More (DE)",
      showAll: "Show All (DE)"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "(DE) Error 404",
        pageNotFound: "(DE) Page not found",
        required: "Erforderlich",
        somethingWentWrong: "(DE) Something went wrong",
        invalidEmail: "(DE) Invalid email address",
        mustBeNumber: "(DE) Must be a number",
        mustBeOlder: "(DE) Sorry, you must be at least 19 years old",
        mustAcceptTC: "(DE) You must accept terms and conditions",
        invalidFiscalCode: "",
        invalidBirthdayDate: "(DE) Invalid birthday date"
      },
      messages: {
        successfulSignUp: "(DE) You have signed up successfully",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "(DE) Sign Up",
        fillForm: "(DE) Please fill in the form",
        fullName: "(DE) full name",
        email: "(DE) email",
        mobileNumber: "(DE) mobile number",
        age: "(DE) age",
        termsConditions: "nutzungsbedingungen",
        privacyPolicy: "privacy-policy",
        ageConfirmation: "(DE) I confirm I am over 19 yrs",
        subscriptionConfirmation: "(DE) I want to receive an Email and/or SMS about promotions",
        accept: "(DE) I accept the",
        terms: "(DE) terms",
        promotions: "(DE) promotions",
        submit: "(DE) submit",
        fiscalCode: "(DE )Fiscal code",
        birthdayDate: "(DE) Date of birth",
        close: "Close",
        search: "Search",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  it: {
    slugs: {
      games: "giochi",
      promotions: "promozioni",
      partnerships: "[IT] partnerships",
      category: "categoria",
      signUp: "resta-aggiornato",
      success: "success",
      termsConditions: "termini-e-condizioni",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Giochi",
      promotions: "Promozioni",
      signUp: "Resta Aggiornato",
      pastEvents: "",
      allGames: "Tutti i giochi",
      viewMore: "Scopri di piu'",
      showAll: "Mostra tutti"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "Errore 404",
        pageNotFound: "Pagina non trovata",
        required: "Campo obbligatorio",
        somethingWentWrong: "Qualcosa e' andato storto",
        invalidEmail: "Indirizzo email non valido",
        mustBeNumber: "Il campo deve essere numerico",
        mustBeOlder: "Spiacenti, devi avere almeno 19 anni",
        mustAcceptTC: "E' necessario accettare termini e condizioni",
        invalidFiscalCode: "Il campo non e' valido",
        invalidBirthdayDate: "Data di nascita non valida"
      },
      messages: {
        successfulSignUp: "Registrazione avvenuta con successo",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "Resta Aggiornato",
        fillForm: "Compila il form",
        fullName: "Nome e Cognome",
        email: "Indirizzo E-Mail",
        mobileNumber: "Numero di Cellulare",
        age: "Età",
        termsConditions: "Termini e condizioni",
        privacyPolicy: "privacy-policy",
        ageConfirmation: " Dichiaro di essere maggiorenne",
        subscriptionConfirmation:
          "Voglio usufruire del servizio di aggiornamento gratuito sulle caratteristiche del servizio di gioco, sui nuovi prodotti e sui servizi di LeoVegas.it.",
        accept: "Ho letto e accetto le",
        terms: "condizioni",
        promotions: "promozioni",
        submit: "invia",
        fiscalCode: "Codice Fiscale",
        birthdayDate: "Data di Nascita",
        close: "Close",
        search: "Cerca",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  br: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "jogos",
      promotions: "promocoes",
      partnerships: "[BR] partnerships",
      category: "categoria",
      signUp: "registrar-se",
      success: "exito",
      termsConditions: "termos",
      privacyPolicy: "politica-de-privacidade",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Jogos",
      promotions: "Promoções",
      signUp: "Registrar-se",
      pastEvents: "Eventos Passados",
      allGames: "Todos os Jogos",
      viewMore: "Ver Mais",
      showAll: "Mostrar Todos"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "Erro 404",
        pageNotFound: "Página não encontrada",
        required: "Obrigatório",
        somethingWentWrong: "Algo correu mal",
        invalidEmail: "E-mail inválido",
        mustBeNumber: "Deve de ser um número",
        mustBeOlder: "Desculpe, tem de ter pelo menos 18 anos",
        mustAcceptTC: "Tem de aceitar os Termos e Condições",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Data de nascimento inválida"
      },
      messages: {
        successfulSignUp: "Registro bem sucedido",
        noResultGameSearch: "Nenhum resultado encontrado para ",
        searchAdvice: "Certifique-se de que suas palavras estejam digitadas corretamente ou use menos ou tente palavras-chave diferentes"
      },
      labels: {
        signUp: "Registrar-se",
        fillForm: "Por favor preencha o formulário",
        fullName: "Nome completo",
        email: "Email (obrigatório)",
        mobileNumber: "Celular (obrigatório)",
        age: "idade",
        termsConditions: "Termos e Condições",
        privacyPolicy: "Política de Privacidade",
        ageConfirmation: "Confirmo que tenho mais de 18 anos",
        subscriptionConfirmation:
          "Quero ser contactado e informado sobre os produtos e serviços do grupo LeoVegas Gaming Plc (novos recursos, campanhas e ofertas) por e-mail / SMS",
        accept: "Confirmo que li e entendi a",
        terms: "termos",
        promotions: "promoções",
        submit: "Enviar",
        fiscalCode: "Código fiscal",
        birthdayDate: "Data de nascimento",
        close: "Fechar",
        search: "pesquisar",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  no: {
    metaTitle: "The King of Free 2 Play Casino ",
    slugs: {
      games: "games",
      promotions: "promotions",
      partnerships: "partnerships",
      category: "category",
      signUp: "sign-up",
      success: "success",
      termsConditions: "terms-and-conditions",
      pastEvents: "past-events",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Games",
      promotions: "Promotions",
      signUp: "Sign Up",
      pastEvents: "Past Events",
      allGames: "All Games",
      viewMore: "View More",
      showAll: "Show All",
      language: "Language"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "Error 404",
        pageNotFound: "Page not found",
        required: "Required",
        somethingWentWrong: "Something went wrong",
        invalidEmail: "Invalid email address",
        mustBeNumber: "Must be a number",
        mustBeOlder: "Sorry, you must be at least 18 years old",
        mustAcceptTC: "You must accept terms and conditions",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Invalid birthday date"
      },
      messages: {
        successfulSignUp: "Success! Check email for more details. If email does not arrive immediately, please check your junk mail folder!",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "Sign Up",
        fillForm: "Please fill in the form",
        fullName: "full name",
        email: "email",
        mobileNumber: "mobile number",
        age: "age",
        termsConditions: "terms and conditions",
        privacyPolicy: "Privacy Policy",
        ageConfirmation: "I confirm I am over 18 years old",
        subscriptionConfirmation:
          "I want to be contacted and informed about LeoVegas Gaming Plc’s products and services (new features, campaigns and offers) via Email/SMS",
        accept: "I confirm that I have read and understood the",
        terms: "terms",
        promotions: "promotions",
        submit: "submit",
        fiscalCode: "Fiscal code",
        birthdayDate: "Date of birth",
        close: "Close",
        search: "Search",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  /*
  no: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "spill",
      promotions: "kampanjer",
      partnerships: "[NO] partnerships",
      category: "kategori",
      signUp: "registrer-deg",
      success: "suksess",
      termsConditions: "vilkar-betingelser",
      pastEvents: "tidligere-hendelser",
      privacyPolicy: "personvernerklaering",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Spill",
      promotions: "Kampanjer",
      signUp: "Nyhetsbrev",
      pastEvents: "Tidligere hendelser",
      allGames: "Alle Spill",
      viewMore: "Se mer",
      showAll: "Vis alle"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "Error 404",
        pageNotFound: "Siden ble ikke funnet",
        required: "Påkrevd",
        somethingWentWrong: "Noe gikk galt",
        invalidEmail: "Ugyldig epost-adresse",
        mustBeNumber: "Må være et tall",
        mustBeOlder: "Beklager, du må minimum være 18 år",
        mustAcceptTC: "Du må akseptere vilkår & betingelser",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Ugyldig fødselsdato"
      },
      messages: {
        successfulSignUp: "Du har suksessfullt registrert deg",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "Registrer deg",
        fillForm: "Vennligst fyll inn skjemaet",
        fullName: "fullt navn",
        email: "epost",
        mobileNumber: "mobilnummer",
        age: "alder",
        termsConditions: "vilkår & betingelser",
        privacyPolicy: "personvernerklæringen",
        ageConfirmation: "Jeg bekrefter at jeg er over 18 år",
        subscriptionConfirmation:
          "Jeg vil fra tid til annen bli informert om LeoVegas Gaming Plc.’s produkter og -tjenester (nye tjenester, kampanjer og tilbud) via e-post / SMS.",
        accept: "Jeg har lest og forstår",
        terms: "vilkår",
        promotions: "kampanjer",
        submit: "Registrer deg",
        fiscalCode: "Skattekode",
        birthdayDate: "Fødselsdag",
        close: "Lukk",
        search: "Søk",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
   fi: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "pelit",
      promotions: "kampanjat",
      partnerships: "[FI] partnerships",
      category: "kategoria",
      signUp: "liity",
      success: "onnistui",
      termsConditions: "säännöt-ja-ehdot",
      pastEvents: "menneet-tilaisuudet",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Pelit",
      promotions: "Kampanjat",
      signUp: "Liity",
      pastEvents: "Menneet tilaisuudet",
      allGames: "Kaikki pelit",
      viewMore: "Katso lisää",
      showAll: "Näytä kaikki"
    },
    application: {
      game: {
        relaunchGame: "Aloita peli"
      },
      warnings: {},
      errors: {
        error404: "Error 404",
        pageNotFound: "Sivua ei löytynyt",
        required: "Tarvitaan",
        somethingWentWrong: "Jotain meni pieleen",
        invalidEmail: "Epäkelpo sähköposti",
        mustBeNumber: "Tulee olla numero",
        mustBeOlder: "Sinun tulee olla vähintään 18-vuotias pelataksesi",
        mustAcceptTC: "Sinun tulee hyväksyä säännöt ja ehdot",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Epäkelpo syntymäpäivä"
      },
      messages: {
        successfulSignUp: "Olet rekisteröitynyt onnistuneesti"
      },
      labels: {
        signUp: "Liity mukaan",
        fillForm: "Anna seuraavat tiedot",
        fullName: "koko nimi",
        email: "sähköpostiosoite",
        mobileNumber: "matkapuhelinnumero",
        age: "ikä",
        termsConditions: "säännöt ja ehdot",
        privacyPolicy: "yksityisyydensuoja",
        ageConfirmation: "Vahvistan olevani yli 18-vuotias",
        subscriptionConfirmation: "Haluan vastaanottaa tarjouksia sähköpostilla ja/tai tekstiviestinä",
        accept: "Hyväksyn",
        terms: "ehdot",
        promotions: "kampanjat",
        submit: "Lähetä",
        fiscalCode: "Fiscal code",
        birthdayDate: "Syntymäaika",
        close: "Sulje",
        search: "Etsi",
        skipSignup: "Skip signup and go to games"
      }
    }
  }, */
  fi: {
    metaTitle: "The King of Free 2 Play Casino ",
    slugs: {
      games: "games",
      promotions: "promotions",
      partnerships: "partnerships",
      category: "category",
      signUp: "sign-up",
      success: "success",
      termsConditions: "terms-and-conditions",
      pastEvents: "past-events",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Games",
      promotions: "Promotions",
      signUp: "Sign Up",
      pastEvents: "Past Events",
      allGames: "All Games",
      viewMore: "View More",
      showAll: "Show All",
      language: "Language"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "Error 404",
        pageNotFound: "Page not found",
        required: "Required",
        somethingWentWrong: "Something went wrong",
        invalidEmail: "Invalid email address",
        mustBeNumber: "Must be a number",
        mustBeOlder: "Sorry, you must be at least 18 years old",
        mustAcceptTC: "You must accept terms and conditions",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Invalid birthday date"
      },
      messages: {
        successfulSignUp: "Success! Check email for more details. If email does not arrive immediately, please check your junk mail folder!",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "Sign Up",
        fillForm: "Please fill in the form",
        fullName: "full name",
        email: "email",
        mobileNumber: "mobile number",
        age: "age",
        termsConditions: "terms and conditions",
        privacyPolicy: "Privacy Policy",
        ageConfirmation: "I confirm I am over 18 years old",
        subscriptionConfirmation:
          "I want to be contacted and informed about LeoVegas Gaming Plc’s products and services (new features, campaigns and offers) via Email/SMS",
        accept: "I confirm that I have read and understood the",
        terms: "terms",
        promotions: "promotions",
        submit: "submit",
        fiscalCode: "Fiscal code",
        birthdayDate: "Date of birth",
        close: "Close",
        search: "Search",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  se: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "pelit",
      promotions: "kampanjat",
      partnerships: "[SE] partnerships",
      category: "kategoria",
      signUp: "liity",
      success: "onnistui",
      termsConditions: "säännöt-ja-ehdot",
      pastEvents: "menneet-tilaisuudet",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Pelit",
      promotions: "Kampanjat",
      signUp: "Liity",
      pastEvents: "Menneet tilaisuudet",
      allGames: "Kaikki pelit",
      viewMore: "Katso lisää",
      showAll: "Näytä kaikki"
    },
    application: {
      game: {
        relaunchGame: "Aloita peli"
      },
      warnings: {},
      errors: {
        error404: "Error 404",
        pageNotFound: "Sivua ei löytynyt",
        required: "Tarvitaan",
        somethingWentWrong: "Jotain meni pieleen",
        invalidEmail: "Epäkelpo sähköposti",
        mustBeNumber: "Tulee olla numero",
        mustBeOlder: "Sinun tulee olla vähintään 18-vuotias pelataksesi",
        mustAcceptTC: "Sinun tulee hyväksyä säännöt ja ehdot",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Epäkelpo syntymäpäivä"
      },
      messages: {
        successfulSignUp: "Olet rekisteröitynyt onnistuneesti",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "Liity mukaan",
        fillForm: "Anna seuraavat tiedot",
        fullName: "koko nimi",
        email: "sähköpostiosoite",
        mobileNumber: "matkapuhelinnumero",
        age: "ikä",
        termsConditions: "säännöt ja ehdot",
        privacyPolicy: "yksityisyydensuoja",
        ageConfirmation: "Vahvistan olevani yli 18-vuotias",
        subscriptionConfirmation: "Haluan vastaanottaa tarjouksia sähköpostilla ja/tai tekstiviestinä",
        accept: "Hyväksyn",
        terms: "ehdot",
        promotions: "kampanjat",
        submit: "Lähetä",
        fiscalCode: "Fiscal code",
        birthdayDate: "Syntymäaika",
        close: "Sulje",
        search: "Etsi",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  jp: {
    metaTitle: "完全無料で遊べるオンラインカジノゲーム☆",
    slugs: {
      games: "games",
      promotions: "promotions",
      partnerships: "partnerships",
      category: "category",
      signUp: "sign-up",
      success: "success",
      termsConditions: "terms-and-conditions",
      pastEvents: "past-events",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "ゲーム",
      promotions: "キャンペーン",
      signUp: "登録する",
      pastEvents: "過去のイベント",
      allGames: "すべてのゲーム",
      viewMore: "もっと見る",
      showAll: "すべて表示"
    },
    application: {
      game: {
        relaunchGame: "ゲームを開く"
      },
      warnings: {},
      errors: {
        error404: "404エラー",
        pageNotFound: "ページが見つかりません",
        required: "未入力です",
        somethingWentWrong: "エラーが発生しました",
        invalidEmail: "無効なメールアドレス",
        mustBeNumber: "数字を入力してください",
        mustBeOlder: "恐れ入りますが、19歳以上である必要があります",
        mustAcceptTC: "利用規約に同意してください",
        invalidFiscalCode: "",
        invalidBirthdayDate: "無効な生年月日"
      },
      messages: {
        successfulSignUp: "登録が完了しました",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "登録する",
        fillForm: "以下の項目をご記入ください",
        fullName: "氏名",
        email: "メールアドレス",
        mobileNumber: "携帯電話番号",
        age: "年齢",
        termsConditions: "利用規約",
        privacyPolicy: "個人情報保護方針",
        ageConfirmation: "18歳以上であることを承認します",
        subscriptionConfirmation: "レオベガスからの製品やサービス (最新情報やキャンペーン) に関する情報をEメールまたはSMSで受信します。",
        accept: "利用規約を読み理解しました",
        terms: "規約",
        promotions: "キャンペーン",
        submit: "送信",
        fiscalCode: "Fiscal code",
        birthdayDate: "生年月日",
        close: "閉じる",
        search: "検索する",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  in: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "games",
      promotions: "promotions",
      partnerships: "[IN] partnerships",
      category: "category",
      signUp: "sign-up",
      success: "success",
      termsConditions: "terms-and-conditions",
      pastEvents: "past-events",
      privacyPolicy: "privacy-policy",
      cookiePolicy: "cookie-policy"
    },
    links: {
      games: "Games",
      promotions: "Promotions",
      signUp: "Sign Up",
      pastEvents: "Past Events",
      allGames: "All Games",
      viewMore: "View More",
      showAll: "Show All"
    },
    application: {
      game: {
        relaunchGame: "Launch game"
      },
      warnings: {},
      errors: {
        error404: "Error 404",
        pageNotFound: "Page not found",
        required: "Required",
        somethingWentWrong: "Something went wrong",
        invalidEmail: "Invalid email address",
        mustBeNumber: "Must be a number",
        mustBeOlder: "Sorry, you must be at least 18 years old",
        mustAcceptTC: "You must accept terms and conditions",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Invalid birthday date"
      },
      messages: {
        successfulSignUp: "Success! Check email for more details. If email does not arrive immediately, please check your junk mail folder!",
        noResultGameSearch: "No results found for ",
        searchAdvice: "Please make sure your words are spelled correctly or use less or different keywords"
      },
      labels: {
        signUp: "Sign Up",
        fillForm: "Please fill in the form",
        fullName: "full name",
        email: "email",
        mobileNumber: "mobile number",
        age: "age",
        termsConditions: "terms and conditions",
        privacyPolicy: "Privacy Policy",
        ageConfirmation: "I confirm I am over 18 yrs",
        subscriptionConfirmation:
          "I want to be informed, from time to time, about LeoVegas Gaming Plc, products, services, promotions and offers via email and/or SMS",
        accept: "I confirm that I have read and understood the",
        terms: "terms",
        promotions: "promotions",
        submit: "submit",
        fiscalCode: "Fiscal code",
        birthdayDate: "Date of birth",
        close: "Close",
        search: "Search",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  es: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "juegos",
      promotions: "promociones",
      partnerships: "[ES] partnerships",
      category: "categoria",
      signUp: "registrarse",
      success: "exito",
      termsConditions: "terminos-y-condiciones",
      privacyPolicy: "politica-de-privacidad",
      cookiePolicy: "politica-de-cookies",
      pastEvents: "eventos-pasados"
    },
    links: {
      games: "juegos",
      promotions: "promociones",
      signUp: "registrarse",
      pastEvents: "eventos pasados",
      allGames: "todos los juegos",
      viewMore: "ver mas",
      showAll: "mostrar todo"
    },
    application: {
      game: {
        relaunchGame: "lanzamiento del juego"
      },
      warnings: {},
      errors: {
        error404: "error 404",
        pageNotFound: "pagina no encontrada",
        required: "obligatorio",
        somethingWentWrong: "algo fue mal",
        invalidEmail: "direccion de correo invalida",
        mustBeNumber: "debe ser un numero",
        mustBeOlder: "lo sentimos, debes tener al menos 18 aÒos",
        mustAcceptTC: "debes aceptar los terinos y condiciones",
        invalidFiscalCode: "codigo fiscal invalido",
        invalidBirthdayDate: "fecha de nacimiento invalida"
      },
      messages: {
        successfulSignUp: "te has registrado con exito",
        noResultGameSearch: "No se encontraron resultados para ",
        searchAdvice: "Asegúrate de que las palabras están escritas correctamente o intenta usar menos palabras claves o palabras diferentes."
      },
      labels: {
        signUp: "registrarse",
        fillForm: "por favour rellena el formulario",
        fullName: "nombre completo",
        email: "correo electronico",
        mobileNumber: "telefono movil",
        age: "edad",
        termsConditions: "terminos y condiciones",
        privacyPolicy: "Política de privacidad",
        ageConfirmation: "confirmo que tengo mas de 18 aÒos",
        subscriptionConfirmation: "quiero recibir correos y/o sms sobre promociones",
        accept: "acepto el",
        terms: "tÈrminos",
        promotions: "promociones",
        submit: "enviar",
        fiscalCode: "codigo fiscal",
        birthdayDate: "fecha de nacimiento",
        close: "cerrado",
        search: "buscar",
        skipSignup: "Skip signup and go to games"
      }
    }
  },
  fr: {
    metaTitle: "The King of Free 2 Play Casino",
    slugs: {
      games: "jeux",
      promotions: "promotions",
      partnerships: "partenariats",
      category: "categories",
      signUp: "sinscrire",
      success: "succes",
      termsConditions: "conditions-generales",
      pastEvents: "evenements-passes",
      privacyPolicy: "politique-de-confidentialite",
      cookiePolicy: "politique-de-cookies"
    },
    links: {
      games: "Jeux",
      promotions: "Promotions",
      signUp: "S'Inscrire",
      pastEvents: "Evènements Passés",
      allGames: "Tous les Jeux",
      viewMore: "Voir Plus",
      showAll: "Afficher Tout",
      language: "Langue"
    },
    application: {
      game: {
        relaunchGame: "Lancer le jeu"
      },
      warnings: {},
      errors: {
        error404: "Erreur 404",
        pageNotFound: "Page non trouvée",
        required: "Requis",
        somethingWentWrong: "Un problème est survenu",
        invalidEmail: "Adresse e-mail non valide",
        mustBeNumber: "Doit être un numéro",
        mustBeOlder: "Désolé, vous devez avoir au moins 18 ans",
        mustAcceptTC: "Vous devez accepter les conditions générales",
        invalidFiscalCode: "",
        invalidBirthdayDate: "Date d'anniversaire non valide"
      },
      messages: {
        successfulSignUp: "Succès! Consultez votre email pour plus de détails!",
        noResultGameSearch: "Aucun résultat trouvé pour ",
        searchAdvice:
          "Merci de vérifier que tes mots sont correctement orthographiés ou essaye d’utiliser moins de mots clés ou bien des mots clés différents"
      },
      labels: {
        signUp: "S'Inscrire",
        fillForm: "Veuillez remplir le formulaire",
        fullName: "Nom et Prénom",
        email: "E-mail",
        mobileNumber: "Numéro de Portable",
        age: "âge",
        termsConditions: "Conditions Générales",
        privacyPolicy: "Politique De Confidentialité",
        ageConfirmation: "Je confirme avoir plus de 18 ans",
        subscriptionConfirmation:
          "Je souhaite être contacté et informé des produits et services de LeoVegas Gaming PLC (nouvelles fonctionnalités, promotions et offres) par e-mail/SMS",
        accept: "Je confirme avoir lu et compris la Politique De Confidentialité",
        terms: "termes",
        promotions: "promotions",
        submit: "Soumettre",
        fiscalCode: "code fiscal",
        birthdayDate: "Date de naissance",
        close: "Fermer",
        search: "Rechercher",
        skipSignup: "Ignorer l'inscription et accéder aux jeux"
      }
    }
  }
}

export default translations
