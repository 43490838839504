import * as PropTypes from "prop-types"
import React, { useState } from "react"

import Logo from "./Logo"
import Navigation from "./Navigation"

const MobileMenu = ({ list, showLangMenu, handleLanguageChange, availableLang, userLang, logo }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const handleMenuButtonClick = () => {
    setIsMenuVisible(true)
    document.body.style.overflow = "hidden"
  }

  const handleMenuCloseButtonClick = () => {
    document.body.style.overflow = "auto"
    setIsMenuVisible(false)
  }

  const buttonModify = isMenuVisible ? "--close" : ""
  const menuModifier = isMenuVisible ? "--open" : "--close"

  return (
    <>
      <button
        className={`burger__button burger__button${buttonModify}`}
        type="button"
        // eslint-disable-next-line react/no-unknown-property
        isOpen={isMenuVisible}
        onClick={isMenuVisible ? handleMenuCloseButtonClick : handleMenuButtonClick}
      >
        <span className="bars" />
        <span className="bars" />
        <span className="bars" />
      </button>
      <div className={`burger__overlay burger__overlay${menuModifier}`} />
      <div className={`burger__wrap burger__wrap${menuModifier}`}>
        <div className="burger__logo">
          <Logo modifier="logo" url={logo} />
        </div>
        <Navigation
          list={list}
          onClick={() => {
            handleMenuCloseButtonClick()
          }}
          showLangMenu={showLangMenu}
          handleLanguageChange={handleLanguageChange}
          availableLang={availableLang}
          userLang={userLang}
        />
      </div>
    </>
  )
}

MobileMenu.propTypes = {
  list: PropTypes.arrayOf(PropTypes.any).isRequired,
  showLangMenu: PropTypes.bool.isRequired,
  handleLanguageChange: PropTypes.func,
  availableLang: PropTypes.arrayOf(PropTypes.any),
  userLang: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired
}

MobileMenu.defaultProps = {
  handleLanguageChange: null,
  availableLang: null
}

export default MobileMenu
