import * as PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

import Picture from "./Picture"
import { pushDatalayer } from "../helpers"

const Logo = ({ modifier, url }) => (
  <Link to="/" className={`logo ${modifier}`} onClick={() => pushDatalayer("logoClick", "Logo", "Redirect")}>
    <Picture src={url} alt="" className="logo__image" useWebp>
      <img src={url} loading="eager" alt="" className="logo__image" width="auto" height="100%" />
    </Picture>
  </Link>
)

Logo.propTypes = {
  modifier: PropTypes.string,
  url: PropTypes.string
}

Logo.defaultProps = {
  modifier: "",
  url: ""
}

export default Logo
