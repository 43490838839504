/* eslint-disable react/no-unknown-property */
import PropTypes from "prop-types"
import React from "react"

const Picture = ({ src, useWebp, quality, children }) => {
  const params = new URLSearchParams()

  if (useWebp) params.append("fm", "webp")
  if (quality) params.append("q", quality)

  const source = params.keys().length === 0 ? src : `${src}?${params.toString()}`

  return (
    <picture>
      <source srcSet={source} type="image/webp" />
      {children}
    </picture>
  )
}

export const defaultTypes = {
  src: PropTypes.string.isRequired,
  useWebp: PropTypes.bool,
  quality: PropTypes.number,
  children: PropTypes.node.isRequired
}

export const defaultProps = {
  useWebp: false,
  quality: null
}

Picture.propTypes = defaultTypes
Picture.defaultProps = defaultProps

export default Picture
