import { VIEW_MORE, TOGGLE_LIGHTBOX, UPDATE_INDEX } from "../actions/types"

const initialState = {
  isVisible: false,
  lightboxIsOpen: false,
  selectedIndex: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case VIEW_MORE:
      return {
        ...state,
        isVisible: true
      }
    case TOGGLE_LIGHTBOX:
      return {
        ...state,
        lightboxIsOpen: action.lightboxIsOpen
      }
    case UPDATE_INDEX:
      return {
        ...state,
        selectedIndex: action.selectedIndex
      }

    default:
      return state
  }
}
