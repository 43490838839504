import { FETCH_GTM_AVAILABILITY } from "./types"

const fetchGtmAvailability = () => async dispatch => {
  await fetch("/json/false.json")
    .then(response => response.json())
    .then(data => {
      dispatch({
        type: FETCH_GTM_AVAILABILITY,
        isGtmAvailable: data.state
      })
    })
}

export default fetchGtmAvailability
